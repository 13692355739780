<template>
    <ODialog @shown="shown()" v-model:show="showDialog" size="xl" backdrop :title="$t('Data Log')">
        <div class="o365-dialog-body" >
            <ODataLookup :dataObject="dsDataLogGroupBy" v-model="dsDataLogGroupBy.current.Created" class="form-control mb-2" >
                <OColumn field="Created" width="500px" />
            </ODataLookup>
            <ODataGrid :dataObject="dsDataLog" hideActionColumn hideSystemColumn 
                hideMultiselectColumn hideGridMenu  loadDataObject 
                style="min-height: 300px; height: 400px; max-height: 500px;"
            >
                <OColumn v-for="col of distinctColumns" :filter="false" :colId="col" :key="`${uniqueKey}-${col}`">
                    <template #default="{ row }">
                        {{ row.DataJson[col] }}
                    </template>
                </OColumn>
                <OColumn field="Status" v-slot="{ row }" width="60px">
                    <span :style="row.Status == 'Success' ? 'color: green;' : 'color: red;'">{{ row.Status }}</span>
                </OColumn>
                <OColumn field="Message" autoHeight width="500px" />
            </ODataGrid>
        </div>
    </ODialog>
</template>
<script setup>
import { ref, computed } from 'vue';
import { ODialog } from 'o365-ui-components';
const props = defineProps({
    dataObject: null
  
});

const showDialog = ref(false);
const modalRef = {
    show: () => showDialog.value = true,
    hide: () => showDialog.value = false,
}
defineExpose({modalRef})

const random =  "_" + crypto.randomUUID();

const dsDataLogGroupBy = $getOrCreateDataObject({
    "id": "dsDataLogGroupBy"+random,
    "viewName": "sviw_O365_DataImportLogGroupBy",
    "selectFirstRowOnLoad": true,
    "fields": [
        { "name": "DBObject_ID"},{"name": "GroupKey"},{"name": "SortOrder","sortOrder": 1,"sortDirection": 'desc'},{"name": "Created"}
    ],
    "maxRecords": 100,
    "dynamicLoading": false,
    "whereClause": `[DBObject_ID] = '${props.dataObject.uniqueTable || props.dataObject.viewName}'`
})

const dsDataLog = $getOrCreateDataObject({
    "id": "dsDataLog"+random,
    "viewName": "stbv_O365_DataImportLog",
    "selectFirstRowOnLoad": true,
    "fields": [{"name": "PrimKey"},{"name": "ID"}, {"name": "Created","type":"dateTime"},
        {"name": "CreatedBy_ID"}, { "name": "Updated","type":"dateTime"},
        {"name": "UpdatedBy_ID"},{"name": "DBObject_ID"},{"name": "DataRow"},
        {"name": "Status"},{"name": "Message"},{"name": "GroupKey" },{"name": "RowPrimKey"},
    ],
    "masterDataObject_ID": "dsDataLogGroupBy"+random,
    "masterDetailDefinition": [
        {
            "detailField": "GroupKey",
            "operator": "equals",
            "masterField": "GroupKey"
        }
    ]
})
const shown = () =>{
    dsDataLogGroupBy.load();
}


dsDataLog.fields.DataRow.jsonAlias = "DataJson";
const uniqueKey = ref('');
const distinctColumns = computed(() => {
    const arr = [];
    for (const data of dsDataLog.data) {
        for (const jsonData in data.DataJson) {
            if (!arr.includes(jsonData)) {
                arr.push(jsonData); 
            }
        }
    }
    uniqueKey.value = arr.join('');
    return arr;
})


</script>